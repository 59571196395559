import React from 'react';
import './Skills.css';

function Skills() {
  return (
    <div className="skills">
      <h2>My Skills</h2>
      <p>
        Below is an overview of my technical skills and personal experiences that highlight my development in various areas.
      </p>
      <div className="skills-list">
        <div className="skill-item">
          <h3>Programming Languages</h3>
          <p>Python, C++, Java</p>
        </div>
        <div className="skill-item">
          <h3>Web Development</h3>
          <p>HTML, CSS, JavaScript</p>
        </div>
        <div className="skill-item">
          <h3>Game Development</h3>
          <p>Unreal Engine</p>
        </div>
        <div className="skill-item">
          <h3>Other Skills</h3>
          <p>SQL</p>
        </div>
      </div>

      <div className="experience">
        <h2>Other Skills/Experiences</h2>
        <ul>
          <li>Built multiple gaming computers for friends and myself</li>
          <li>Visited EA Headquarters for a taster day</li>
          <li>Community Manager for a cryptocurrency Telegram group</li>
          <li>Assisted in teaching Taekwondo lessons</li>
          <li>Competent and confident around computer systems and my capabilities to troubleshoot and resolve technical issues</li>
        </ul>
      </div>
    </div>
  );
}

export default Skills;
