import React from 'react';
import './DownloadButton.css';
import Resume from '../Assets/BillyCoyne.pdf'

function DownloadResume() {
  return (
    <div className="download-resume">
      <a href={Resume} target="_blank" rel="noopener noreferrer">
        <button>Download Resume</button>
      </a>
    </div>
  );
}

export default DownloadResume;
