import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'; 
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function Footer() {
  return (
    <footer className="footer">
      <p>
        Billy Coyne |  
        <Link to="/contact" className="contact-icon">
          <FontAwesomeIcon icon={faCircleInfo} size="lg" />
        </Link>
      </p>
    </footer>
  );
}

export default Footer;
