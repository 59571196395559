import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'; 
import './Contact.css';

function Contact() {
  return (
    <div className="contact">
      <h2>Contact Me</h2>
      <p>If you'd like to get in touch, you can reach me through email and the following platforms:</p>
      <div className="contact-icons">
        {/* Email with tooltip functionality */}
        <a href="mailto:coynebbc@gmail.com" className="email-icon" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faEnvelope} size="2xl" />
        </a>
        {/* LinkedIn */}
        <a href="https://www.linkedin.com/in/billy-coyne-14b818248/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="2xl" />
        </a>
        {/* GitHub */}
        <a href="https://github.com/billybcoyne" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faSquareGithub} size="2xl" />
        </a>
      </div>
    </div>
  );
}

export default Contact;
