import React from 'react';
import './Resume.css';
import DownloadResume from './DownloadResume'; // Import the DownloadResume component

function Resume() {
  return (
    <div className="resume">
      <h2>My Resume</h2>
      <p>
        You can find my resume here. Feel free to download it and learn more about my experience and skills.
      </p>
      <DownloadResume /> 
    </div>
  );
}

export default Resume;
