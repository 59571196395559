import React from 'react';
import './AboutMe.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faDumbbell, faMicrochip } from '@fortawesome/free-solid-svg-icons'; 
import AboutMeCarousel from './AboutMeCarousel'; // Make sure the path is correct

function AboutMe() {
  return (
    <div className="about-me">
      <div className="about-me-content">
        {/* Replace the billeh.jpg image with the carousel */}
        <AboutMeCarousel /> {/* This will render your carousel */}
        <div className="about-me-text">
          <h2>Hi, I'm Billy!</h2>
          <p>
            I'm a passionate game developer with experience in Unreal Engine 5, C++, Python and Java. I love video games - playing, watching and creating them. Here are some of the projects I've worked on:
          </p>
        </div>
      </div>

      {/* New wrapper for the hobbies and interests section */}
      <div className="hobbies-section">
        <h2>Hobbies and Interests</h2>
        <h3><FontAwesomeIcon icon={faGamepad} /> Gaming</h3>
        <p>
          I have played games both casually and competitively for as long as I can remember. I'm consistently one of the best players in Europe at a tactical FPS game called VALORANT, ranking in the top 0.1% of a European player base estimated at 2.7 million. I have played in professional team environments, attending tournaments both online and in person. Some of my favourite games include Mirrors Edge, Mario Sunshine, Zelda BOTW and TOTK, Resident Evil, Left 4 Dead, TEKKEN, CS.
        </p>
        <h3><FontAwesomeIcon icon={faDumbbell} /> Fitness</h3>
        <p>
          I love all kinds of fitness! It helps me unwind, whilst challenging myself to be better. I take part in gym activities such as weightlifting and boxing, and I love climbing/bouldering. I studied Taekwondo for 15 years, competing in numerous tournaments, and swam for 6 years. I consider myself strong in both disciplines.
        </p>
        <h3><FontAwesomeIcon icon={faMicrochip} /> Technology</h3>
        <p>
          I am a tech enthusiast and have grown up with technology my whole life. I have built multiple computers for myself and friends and kept up to date with many of today's modern technologies.
        </p>
      </div>
    </div>
  );
}

export default AboutMe;
