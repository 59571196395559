import React, { useState } from 'react';
import './Games.css'; 
import game1image from '../Assets/game1.jpg';
import game2image from '../Assets/game2.jpg';
import game21image from '../Assets/game21.jpg';
import billysmind from '../Assets/billysmind.mp4';
import DownloadRaceDocumentation from './DownloadRaceDocumentation';

const gameData = [
    {
        id: 1,
        name: "Billy's Mind",
        description: 'A platform game developed entirely in Java.',
        image: '/src/Assets/game3.jpg',
        details: (
            <>
            <p>This is a project built entirely in Java using an engine created by the University. It is a simple platfrom game with 3 levels containing various collision, gameplay elements and options/functionality. </p>
            <p>I have attached a short video that I created showcasing the game and its various elements.</p>
            <video width="480" height="270" controls>
                    <source src={billysmind} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            <p></p>
            </>
        )
    },
    {
        id: 2,
        name: 'Racing Game',
        description: 'A simple racing game developed entirely in C++.',
        image: game1image,
        details: (
            <>
                <p>This is a racing game I created in my final year of university for my computer graphics module. It utilises C++ entirely, creating the shapes and visuals you see entirely by scratch.</p>
                <p>In this game, the player controls a car along a track in the attempt to get the fastest time possible. Featuring multiple different camera angles, shader effects and hand-calculated shapes and environments, this project is a great showcase of my skills.</p>
                <p>Feel free to check out the game details and documentation here!</p>
                <DownloadRaceDocumentation />
            </>
        )
    },
    {
        id: 3,
        name: 'The Warden',
        description: 'A horror game developed in Unreal Engine 5.',
        image: game2image,
        details: (
            <div className="warden-details-container">
                <img src={game21image} alt="The Warden Gameplay" className="warden-image" />
                <div className="warden-text">
                    <p>This is a horror game I developed for my final year university project utilizing Unreal Engine 5...</p>
                    <p>Here is some praise I received from my marker for this project:</p>
                    <ul>
                        <li>“You have successfully created a fun, engaging, and immersive horror game experience...”</li>
                        <li>“The project’s strengths lie in its clear problem definition...”</li>
                        <li>“Overall, your project showcases your skills and potential in game development.”</li>
                    </ul>
                    <p><em>— Dr. Ross Paterson, City, University of London</em></p>
                </div>
            </div>
        )
    },    
];

function Games() {
    const [selectedGame, setSelectedGame] = useState(null);

    const handleGameClick = (game) => {
        setSelectedGame(game);
    };

    const closeDetails = () => {
        setSelectedGame(null);
    };

    return (
        <div className="games">
            <h2>My Games</h2>
            <p>
                Welcome to my games portfolio! Here are some of the exciting game projects I’ve developed:
            </p>
            <div className="game-list">
                {gameData.map((game) => (
                    <div className="game-item" key={game.id} onClick={() => handleGameClick(game)}>
                        <img src={game.image} alt={game.name} className="game-image" />
                        <h3>{game.name}</h3>
                        <p>{game.description}</p>
                    </div>
                ))}
            </div>
            
            {selectedGame && (
                <div className="game-details-overlay">
                    <div className="game-details">
                        <h2>{selectedGame.name}</h2>
                        {selectedGame.details}
                        <button onClick={closeDetails}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Games;
