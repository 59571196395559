import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import billx from '../Assets/billx.jpg';
import graduation from '../Assets/graduation.jpg';
import billeh from '../Assets/billeh.jpg';
import billxatpc from '../Assets/billxatpc.jpg';
import './AboutMeCarousel.css'; // Import your custom CSS for styling

const AboutMeCarousel = () => {
  return (
    <div className="about-me-carousel">
      <Carousel interval={5000}> {/* Ensure fade is applied here */}
        <Carousel.Item className="carousel-item">
          <img
            className="d-block w-100"
            src={billx}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item">
          <img
            className="d-block w-100"
            src={graduation}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item">
          <img
            className="d-block w-100"
            src={billxatpc}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carousel-item">
          <img
            className="d-block w-100"
            src={billeh}
            alt="Forth slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default AboutMeCarousel;
