import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <div className="header">
      <div className="header-content">
        <h1>Billy Coyne</h1>
        <div className="nav-bar">
          <Link to="/home">Home</Link>
          <Link to="/games">Games</Link>
          <Link to="/skills">Skills</Link>
          <Link to="/resume">Resume</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
